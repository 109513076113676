import { graphql, PageProps } from "gatsby";
import { useI18next } from "gatsby-plugin-react-i18next";
import { Helmet } from "react-helmet";
import Layout from "../components/Layout";
import { NewsItem } from "../components/commons/NewsItem";
import { useCallback, useState } from "react";

const NewsListPage = ({ data }: PageProps<Queries.NewsListPageQuery>) => {
  const { t } = useI18next();
  const [maxItems, setMaxItems] = useState<number>(10);

  const handleMoreClick = useCallback(() => {
    setMaxItems((state) => state + 10);
  }, []);

  const title = t("newsTitle");

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta property="og:title" content={title} />
        <meta name="twitter:title" content={title} />
      </Helmet>
      <Layout>
        <main className="mx-auto w-325 py-100 md:w-1106 md:py-160 md:px-0">
          <h1 className="sec-title">News</h1>
          <ul className="mx-auto mt-40 md:mt-80 md:w-759">
            {data.allNews.nodes
              .filter((_, i) => i < maxItems)
              .map((data) => (
                <li key={data.id} className="[&:nth-child(n+2)]:mt-21 md:[&:nth-child(n+2)]:mt-30">
                  <NewsItem {...data} />
                </li>
              ))}
          </ul>
          {maxItems < data.allNews.totalCount && (
            <button
              className="group relative mx-auto mt-40 flex h-50 w-131 items-center md:mt-97"
              onClick={handleMoreClick}
            >
              <span className="btn-link w-131">More</span>
              <img
                src="/assets/img/common/btn-arrow.svg"
                className="absolute right-0 left-0 top-45 mx-auto h-23 w-28 rotate-90 duration-[0.2s] group-hover:top-55"
                width="28"
                height="23"
                alt="Our Team"
              ></img>
            </button>
          )}
        </main>
      </Layout>
    </>
  );
};

export const query = graphql`
  query NewsListPage($language: String!) {
    locales: allLocale(filter: { ns: { in: ["top", "meta"] }, language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    allNews: allNews(filter: { lang: { eq: $language } }) {
      nodes {
        id
        slug
        publishedDate
        thumbnail {
          url
          width
          height
        }
        title
        externalLink
      }
      totalCount
    }
  }
`;

export default NewsListPage;
